import { SxProps } from "@mui/system";
import {
    FABDiameter,
    FABSpace,
} from "../../constants";

export const libraryButton: SxProps = {
    position: "absolute",
    left: FABDiameter + FABSpace - 6,
    top: FABSpace - 6,
};
