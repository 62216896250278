import * as React from "react";

export const poemFont: React.CSSProperties = {
    lineHeight: "1.5em",
    whiteSpace: "pre-line",
};

export const poemTitle: React.CSSProperties = {
    margin: "auto",
};

export const poemContainer: React.CSSProperties = {
    margin: "auto",
    maxWidth: "90ch",
    textAlign: "center",
};

