import * as React from "react";

import { useSocket } from "../App";
import { lineSepString } from "../../constants";
import { spectatorLines } from "./styles";

const Lines = () => {
    const { socket } = useSocket();

    const [ lines, setLines ] = React.useState<Array<Array<string>>>([ [], [], [], [] ]);
    const [ lineEdits, setLineEdits ] = React.useState<Array<string>>([ "", "", "", "" ]);

    React.useEffect(() => {

        const lineSpectatorListener = (poemIndex: number, line: string) => {
            setLines(prevLines => {
                return [ ...prevLines.slice(0, poemIndex), [ ...prevLines[poemIndex], line ], ...prevLines.slice(poemIndex + 1) ];
            },
            );
        };

        const lineEditSpectatorListener = (poemIndex: number, value: string) => {
            setLineEdits(prevLineEdits => {
                return [
                    ...prevLineEdits.slice(0, poemIndex),
                    value,
                    ...prevLineEdits.slice(poemIndex + 1),
                ];
            });
        };

        socket.on("lineSpectator", lineSpectatorListener);
        socket.on("lineEditSpectator", lineEditSpectatorListener);

        setLines([ [], [], [], [] ]);
        socket.emit("getLines");

        return () => {
            socket.off("lineSpectator", lineSpectatorListener);
            socket.off("lineEditSpectator", lineEditSpectatorListener);
        };
    }, [ socket ]);

    return (
        <div className={"lines-outer"} style={spectatorLines}>
            {lines.map((lineArray, poemIndex) => {
                return <div className={"lines-inner"} style={{ marginBottom: "2em" }} key={poemIndex}>
                    <div className={"lines-array"}>{
                        lineArray.join(lineSepString)
                    }</div>
                    <div className={"lines-edit"}>{lineEdits[poemIndex]}</div>
                </div>;
            },
            )}
        </div>
    );
};

export default Lines;
