import * as React from "react";

export const poemsBody: React.CSSProperties = {
    marginBottom: "2em",
    marginTop: "auto",
};

export const poemTitle: React.CSSProperties = {
    margin: "auto",
};
