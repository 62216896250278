import * as React from "react";

export const paginationContainer: React.CSSProperties = {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
};

export const paginatedItems: React.CSSProperties = {
    paddingBottom: "1em",
    paddingTop: "1em",
};
