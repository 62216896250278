import * as React from "react";
import Alert from "@mui/material/Alert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";
import { useClipboard } from "use-clipboard-copy";

import {
    poemsBody,
} from "./styles";
import {
    ILine,
} from "../../types";

import { lineSepString, shortDur } from "../../constants";

function PoemLines({ poemLines, editorColors }: { poemLines: ILine[], editorColors: Record<string, string> }) {
    const clipboard = useClipboard({
        copiedTimeout: shortDur, // timeout duration in milliseconds
    });

    return (
        <div style={poemsBody}>
            {poemLines.map((line) => {
                return <div key={line.lineIndex} style={{ whiteSpace: "pre-line" }}>
                    <span className={"first-part"} key={line.lineIndex} style={{ color: editorColors[line.passerDevice] }}>
                        {line.content.slice(0, line.editLength)}
                    </span>
                    <span className={"second-part"} key={line.lineIndex} style={{ color: editorColors[line.authorDevice] }}>
                        {line.content.slice(line.editLength)}
                    </span>
                </div>;
            })}
            <IconButton onClick={() => clipboard.copy(poemLines.map(line => line.content).join(lineSepString))}>
                <ContentCopyIcon />
            </IconButton>
            {clipboard.copied && <Alert severity="success" style={{ position: "fixed" }}>
                Copied!
            </Alert>}
        </div>
    );
}

export default PoemLines;